<template>
    <!--    二维码打印-->
    <div class="codePrint" v-loading="loading">
        <div class="code-box">
            <img id="qr-code" :src="codeUrl">
        </div>
        <div class="btns">
            <el-button @click="download">
                下载
            </el-button>
            <el-button
                v-print="'#qr-code'"
            >
                打印
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'code-print',
    data() {
        return {
            loading: false,
            codeUrl: '',
        };
    },
    props: {
        extr: {
            type: Object,
        },
    },
    created() {
        this.loading = true;
        const qr_code = this.$queryString(this.extr.code.QueryString, 'qr_code');
        this.codeUrl = location.origin + '/interfaceApi/auth/get_qrcode/' + qr_code + '/10';
        this.loading = false;
    },
    methods: {
        download() {
            const elink = document.createElement('a');
            elink.style.display = 'none';
            elink.href = this.codeUrl;
            elink.download = '二维码.png';
            document.body.appendChild(elink);
            elink.click();
            document.body.removeChild(elink);
        },
    },
};
</script>

<style lang="stylus" scoped>
.codePrint
    width 100%
    height 100%
    display flex
    flex-direction column
    justify-content center
    align-items center
    #qr-code
        width 3.3rem
        height 3.3rem
    .btns
        display flex
        justify-content center
        align-items center
        .el-button
            margin 0.1rem
</style>
